export const Permissions = {
    code_editor: "code_editor",
    faqs_panel: "faqs_panel",
    contact_panel: "contact_panel",
    category_courses: "category_courses",
    category_articles: "category_articles",
    category_news: "category_news",
    gallery: "gallery",
    courses_hours: "courses_hours",
    educational_content: "educational_content",
    article_management: "article_management",
    news_management: "news_management",
    user_management: "user_management",
    push_notification: "push_notification",
    channel_management: "channel_management",
    forum_management: "forum_management",
    invoices_management: "invoices_management",
    menu_management: "menu_management",
    header_management: "header_management",
    coupon_management: "coupon_management",
    auth_management: "auth_management",
    my_courses: "my_courses",
    my_invoices: "my_invoices",
    my_favorite: "my_favorite",
    teacher_courses: "teacher_courses",
    payment_confirmation: "payment_confirmation",
    track_order: "track_order",
    app_setting: "app_setting",
    explore_setting: "explore_setting",
    users_card_bank: "users_card_bank",
    application_manager: "application_manager"
}
