import React from "react"
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle, UncontrolledTooltip, Badge, Media,
} from "reactstrap"
import * as Icon from "react-feather"
import {history} from "../../../history"
import {FormattedMessage} from "react-intl";
import {Link, NavLink} from "react-router-dom";
import {Roles} from "../../roles/ProjectRole";
import {Bell} from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Comment} from "@material-ui/icons";
import {GetRequest} from "../../request";
import Notify from "../../Baran/noty/notify";
import {logout} from "../../../redux/actions/auth/loginActions";
import {Permissions} from "../../roles/ProjectPermissions";

const handleNavigation = (e, path) => {
    e.preventDefault()
    history.push(path)
}
const exit = (props) => {
    if (props.user.token)
        props.logout()
}

const UserDropdown = props => {
    return (
        <DropdownMenu right>
            <DropdownItem
                tag="a"
                href="#"
                onClick={e => handleNavigation(e,
                    props.user.token ? "/panel/profile" : "/"
                )}>
                <Icon.User size={14} className="mr-50"/>
                <span className="align-middle">
                        <FormattedMessage id="EditProfile"/>
                    </span>
            </DropdownItem>
            <DropdownItem divider/>
            <DropdownItem
                tag="a"
                onClick={e => exit(props)}>
                <Icon.Power size={14} className="mr-50"/>
                <span className="align-middle">
                    <FormattedMessage id="Logout"/>
                </span>
            </DropdownItem>
        </DropdownMenu>
    )
}

class NavbarUser extends React.PureComponent {
    state = {
        urlNoty: "/api/moderate/dashboard/notifications",
        navbarSearch: false,
        langDropdown: false,
        notification: {
            comments: [],
            questions: []
        },
        noty_count: 0,
        token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
    }

    handleNavbarSearch = () => {
        this.setState({
            navbarSearch: !this.state.navbarSearch
        })
    }
    timeout;

    componentDidMount() {
        this.getNotification();
    }

    handleLangDropdown = () =>
        this.setState({langDropdown: !this.state.langDropdown});

    getNotification = () => {
        let parent = this;
        this.timeout = setTimeout(() => {
            if (this.props.user.permissions.includes(Permissions.educational_content)) {
                GetRequest(parent.state.urlNoty, {
                    'Authorization': parent.state.token
                }).then((response) => {
                    let result = response.data;
                    console.log("wfklsk", result)
                    if (result.ok) {
                        parent.setState({
                            notification: {
                                comments: result.data.comments,
                                questions: result.data.questions,
                            },
                            noty_count: result.data.comments.length + result.data.questions.length
                        })
                    } else {
                        let message = '';
                        result.errors.map(function (value, i) {
                            message += value[0] + '\n';
                        });
                        Notify('error', 'top-right', message);
                    }
                }).catch((err) => {
                    if (err.response) {
                        if (err.response.status === 401)
                            this.props.logout()
                    }
                }).finally(() => {
                    parent.getNotification();
                });
            }
        }, 10000)
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    render() {
        let userProfile = this.props.user.roles.includes(Roles.Student);
        let permissionEducations = this.props.user.permissions.includes(Permissions.educational_content);
        const comments =
            (Array.isArray(this.state.notification.comments) &&
                this.state.notification.comments.length > 0) && this.state.notification.comments.map((item) => {
                return (
                    <div className="d-flex justify-content-between">
                        <NavLink to={{
                            pathname: '/panel/educational-content-comments',
                            state: {
                                course_id: item.course_id,
                                course_title: item.course_title,
                                course_type: item.course_type
                            }
                        }} className="w-100">
                            <Media className="d-flex align-items-start">
                                <Media left href="#">
                                    <Comment/>
                                </Media>
                                <Media body>
                                    <Media heading className="media-heading" tag="h6">
                                        {item.course_title}
                                    </Media>
                                    <p className="notification-text">
                                        کامنت های تایید نشده
                                    </p>
                                </Media>
                                <div className="position-relative">
                                    <Badge pill color="success" className="badge-up">
                                        {" "}
                                        {item.count_comments}
                                        {" "}
                                    </Badge>
                                </div>
                            </Media>
                        </NavLink>
                    </div>
                )
            })
        const questions =
            (Array.isArray(this.state.notification.questions) &&
                this.state.notification.questions.length > 0) && this.state.notification.questions.map((item) => {
                return (
                    <div className="d-flex justify-content-between">
                        <NavLink to={{
                            pathname: '/panel/educational-content-sessions',
                            state: {
                                course_id: item.course_id,
                                course_title: item.course_title,
                                course_type: item.course_type,
                                session_id: item.session_id
                            }
                        }} className="w-100">
                            <Media className="d-flex align-items-start">
                                <Media left href="#">
                                    <i className="fas fa-chalkboard-teacher"/>
                                </Media>
                                <Media body>
                                    <Media heading className="media-heading" tag="h6">
                                        {item.course_title}
                                    </Media>
                                    <p className="notification-text">
                                        {item.session_title}
                                    </p>
                                </Media>
                                <div className="position-relative">
                                    <Badge pill color="success" className="badge-up">
                                        {" "}
                                        {item.count_questions}
                                        {" "}
                                    </Badge>
                                </div>
                            </Media>
                        </NavLink>
                    </div>
                )
            })

        return (
            <nav className="d-flex justify-content-between w-100">
                <ul className="nav navbar-nav navbar-nav-user my-auto">
                    {(userProfile && !this.props.user.confirmed) &&
                    <li>
                        <NavLink to={{
                            pathname: '/panel/profile',
                            state: {
                                confirmed: true,
                            }
                        }} style={{
                            border: "1px solid #ea5455",
                            padding: "7px 10px",
                            borderRadius: "3px",
                            color: "#ea5455"
                        }}>
                            احراز هویت
                            <Bell className="ml-1"/>
                        </NavLink>
                    </li>
                    }
                </ul>
                <ul className="nav navbar-nav navbar-nav-user">
                    <li className="my-auto">
                        <Link className="mr-1 ml-1" to="/">
                            <Icon.Home
                                id="home_tooltip"
                                size={22}/>
                            <UncontrolledTooltip
                                placement="top"
                                target="home_tooltip">
                                صفحه اصلی وب سایت
                            </UncontrolledTooltip>
                        </Link>
                    </li>
                    {permissionEducations &&
                    <UncontrolledDropdown
                        tag="li"
                        className="dropdown-notification nav-item">
                        <DropdownToggle tag="a" className="nav-link nav-link-label">
                            <Icon.Bell size={21}/>
                            {this.state.noty_count > 0 &&
                            <Badge pill color="primary" className="badge-up">
                                {" "}
                                {this.state.noty_count}
                                {" "}
                            </Badge>
                            }
                        </DropdownToggle>
                        {this.state.noty_count > 0 &&
                        <DropdownMenu tag="ul" right className="dropdown-menu-media">
                            <li className="dropdown-menu-header">
                                <div className="dropdown-header mt-0">
                                    <h3 className="text-white">اعلان ها</h3>
                                </div>
                            </li>
                            <PerfectScrollbar
                                className="media-list overflow-hidden position-relative"
                                options={{
                                    wheelPropagation: false
                                }}>
                                {comments}
                                {questions}
                            </PerfectScrollbar>
                        </DropdownMenu>
                        }
                    </UncontrolledDropdown>
                    }
                    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                            <div className="user-nav d-sm-flex d-none">
                              <span className="user-name text-bold-600">
                                {this.props.user.name + " " + this.props.user.lastname}
                              </span>
                            </div>
                            <span data-tour="user">
                              <img
                                  src={this.props.user.photo}
                                  className="round"
                                  height="40"
                                  width="40"
                                  alt="avatar"
                              />
                            </span>
                        </DropdownToggle>
                        <UserDropdown {...this.props} />
                    </UncontrolledDropdown>
                </ul>
            </nav>
        )
    }
}

export default NavbarUser
