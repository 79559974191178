import React from 'react';
import {NavLink} from "reactstrap";
import {Instagram, Twitter, Youtube} from "react-feather";
import {FaTelegram} from "react-icons/fa";
import {WhatsApp} from "@material-ui/icons";
// import aparatImage from "../../assets/img/elements/aparat.png"
import data from "../../../assets/data/configs/storeConfig.json"

// example link for  aparat  https://aparat.com/web_baran;
// example link for  twitter  https://twitter.com/@RoseAcademmy;
// example link for  telegram  https://www.youtube.com/@RoseAcademmy;
// example link for  whatsapp  https://api.whatsapp.com/send/?phone=09926161764&text&app_absent=0
// example link for  instagram  https://www.instagram.com/web_baran


function RenderMedia() {
    const renderMedia = (data)=>{
        switch (data.media){
            case 'instagram' :
                return <a target='_blank' href={data.link}>
                    <Instagram/>
                </a>
                break;
            case 'telegram' :
                return <a target='_blank' href={data.link}>
                    <FaTelegram/>
                </a>
                break;
            case 'whatsapp' :
                return <a target='_blank' href={data.link}>
                    <WhatsApp/>
                </a>
                break;
            case 'aparat' :
                return <a target='_blank' href={data.link}>
                    {/*<img src={aparatImage} width={20}/>*/}
                </a>
                break;
            case 'youtube' :
                return <a target='_blank' href={data.link}>
                    <Youtube/>
                </a>
                break;
            case 'twitter' :
                return <a target='_blank' href={data.link}>
                    <Twitter/>
                </a>
                break;
        }
    }
    return <>
        { data.socialMedia?.map(item => renderMedia(item)) }
    </>;
}

export default RenderMedia;