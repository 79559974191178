import jwtDecode from "jwt-decode";

const initialState = {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    user_id: '',
    name: '',
    lastname: '',
    email: '',
    national_code: '',
    date_of_birth: '',
    federation_code: '',
    cheese_id: '',
    li_cheese: '',
    postal_code: '',
    address: '',
    how_know_us: '',
    rejected_documentation_description: '',
    fide_code: '',
    phone: '',
    suspend: '',
    confirmed: '',
    roles: [],
    permissions: []
    //roles: ['manager'],

    //Supporter Permission
    /*permissions: [
        'setting_chatroom',
        'invoices_management'
    ]*/

    //Admin Permission
    // permissions: [
    //     'category',
    //     'courses_hours',
    //     'courses_setting',
    //     'educational_content',
    //     'article_management',
    //     'student_management',
    //     'setting_chatroom',
    //     'push_notification',
    //     'channel_management',
    //     'supporter_management',
    //     'invoices_management',
    //     'menu_management',
    //     'header_management',
    //     'coupon_management'
    // ]
}
export const login = (state = {}, action) => {
    switch (action.type) {
        case "CheckPhone": {
            if (action.payload.token)
                return {
                    CheckPhoneToken: action.payload.token,
                    CheckPhoneStatus: action.payload.status
                }
            else
                return {
                    CheckPhoneError: action.payload
                }
        }
        case "LOGIN": {
            let user;
            let loginToken = action.payload.token;
            if (action.payload.status === 0) {
                return {
                    checkLoginToken: action.payload.token,
                    checkLoginStatus: 0
                }
            } else if (action.payload.status === 1) {
                try {
                    user = jwtDecode(loginToken);
                    return {
                        ...initialState,
                        token: user.token,
                        user_id: user.user_id,
                        name: user.name,
                        lastname: user.lastname,
                        email: user.email,
                        phone: user.phone,
                        national_code: user.national_code,
                        date_of_birth: user.date_of_birth,
                        rejected_documentation_description: user.rejected_documentation_description,
                        federation_code: user.federation_code,
                        li_cheese: user.li_cheese,
                        address: user.address,
                        how_know_us: user.how_know_us,
                        postal_code: user.postal_code,
                        cheese_id: user.cheese_id,
                        fide_code: user.fide_code,
                        country_code: user.country_code,
                        photo: user.image,
                        suspend: user.suspend,
                        confirmed: user.confirmed,
                        // roles: customer.roles
                        roles: user.roles,
                        permissions: user.permissions,
                        checkLoginStatus: 1
                    };
                } catch (e) {
                    localStorage.removeItem("token");
                    return state;
                }
            } else {
                user = jwtDecode(action.payload);
                return {
                    ...initialState,
                    token: user.token,
                    user_id: user.user_id,
                    name: user.name,
                    lastname: user.lastname,
                    email: user.email,
                    national_code: user.national_code,
                    date_of_birth: user.date_of_birth,
                    federation_code: user.federation_code,
                    rejected_documentation_description: user.rejected_documentation_description,
                    fide_code: user.fide_code,
                    li_cheese: user.li_cheese,
                    how_know_us: user.how_know_us,
                    address: user.address,
                    cheese_id: user.cheese_id,
                    postal_code: user.postal_code,
                    phone: user.phone,
                    country_code: user.country_code,
                    photo: user.image,
                    suspend: user.suspend,
                    confirmed: user.confirmed,
                    // roles: customer.roles
                    roles: user.roles,
                    permissions: user.permissions,
                    checkLoginStatus: 1
                };
            }
        }
        case "REGISTER": {
            let user;
            try {
                user = jwtDecode(action.payload);
                return {
                    ...initialState,
                    token: user.token,
                    user_id: user.user_id,
                    name: user.name,
                    lastname: user.lastname,
                    email: user.email,
                    phone: user.phone,
                    national_code: user.national_code,
                    rejected_documentation_description: user.rejected_documentation_description,
                    date_of_birth: user.date_of_birth,
                    federation_code: user.federation_code,
                    cheese_id: user.cheese_id,
                    postal_code: user.postal_code,
                    fide_code: user.fide_code,
                    li_cheese: user.li_cheese,
                    how_know_us: user.how_know_us,
                    address: user.address,
                    country_code: user.country_code,
                    photo: user.image,
                    suspend: user.suspend,
                    confirmed: user.confirmed,
                    // roles: customer.roles
                    roles: user.roles,
                    permissions: user.permissions
                };
            } catch (e) {
                localStorage.removeItem("token");
                return state;
            }
        }
        case "ERROR": {
            return {
                Error: action.payload
            }
        }
        default: {
            return state
        }
    }
}
