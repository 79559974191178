import Noty from "noty";
import mojs from "mo-js";
import {toast} from "react-toastify";
// layout ||  top-left , top-right , top-center , bottom-left , bottom-right , bottom-center
// types  ||  success , info , warning , error , default
// theme  ||  light , dark , colored
export default function sendNoty(type, layout, text, timeout = 10000, theme = 'colored',
                                 options = {
                                     theme: theme,
                                     position: layout,
                                     autoClose: timeout,
                                     hideProgressBar: false,
                                     closeOnClick: true,
                                     pauseOnHover: true,
                                     draggable: true,
                                     progress: undefined
                                 }) {
    switch (type) {
        case "success":
            toast.success(text, options);
            break;
        case "info":
            toast.info(text, options);
            break;
        case "warning":
            toast.warn(text, options);
            break;
        case "error":
            toast.error(text, options);
            break;
        case "default":
            toast(text, options);
            break;
        default:
            toast('🦄 Wow so easy!', options);
            break;
    }
}

function sendNotyOld(type, layout, text, timeout = 2500) {
    new Noty({
        layout: layout,
        type: type,
        text: text,
        timeout: timeout,
        progressBar: true,
        animation: {
            open: function (promise) {
                var n = this;
                var Timeline = new mojs.Timeline();
                var body = new mojs.Html({
                    el: n.barDom,
                    x: {500: 0, delay: 0, duration: 500, easing: 'elastic.out'},
                    isForce3d: true,
                    onComplete: function () {
                        promise(function (resolve) {
                            resolve();
                        })
                    }
                });

                var parent = new mojs.Shape({
                    parent: n.barDom,
                    width: 200,
                    height: n.barDom.getBoundingClientRect().height,
                    radius: 0,
                    x: {[150]: -150},
                    duration: 1.2 * 500,
                    isShowStart: true
                });

                n.barDom.style['overflow'] = 'visible';
                parent.el.style['overflow'] = 'hidden';

                var burst = new mojs.Burst({
                    parent: parent.el,
                    count: 10,
                    top: n.barDom.getBoundingClientRect().height + 75,
                    degree: 90,
                    radius: 75,
                    angle: {[-90]: 40},
                    children: {
                        fill: '#ffffff',
                        delay: 'stagger(500, -50)',
                        radius: 'rand(8, 25)',
                        direction: -1,
                        isSwirl: true
                    }
                });

                var fadeBurst = new mojs.Burst({
                    parent: parent.el,
                    count: 2,
                    degree: 0,
                    angle: 75,
                    radius: {0: 100},
                    top: '90%',
                    children: {
                        fill: '#ffffff',
                        pathScale: [.65, 1],
                        radius: 'rand(12, 15)',
                        direction: [-1, 1],
                        delay: .8 * 500,
                        isSwirl: true
                    }
                });

                Timeline.add(body, burst, fadeBurst, parent);
                Timeline.play();
            },
            close: function (promise) {
                var n = this;
                new mojs.Html({
                    el: n.barDom,
                    x: {0: 500, delay: 10, duration: 500, easing: 'cubic.out'},
                    skewY: {0: 10, delay: 10, duration: 500, easing: 'cubic.out'},
                    isForce3d: true,
                    onComplete: function () {
                        promise(function (resolve) {
                            resolve();
                        })
                    }
                }).play();
            }
        }
    }).show();
}
