import React from 'react';
import styled from 'styled-components';
import MobileMenu from "../MobileMenu";
import logoImg from '../../../../assets/img/logo/logo2.png'
import {NavLink} from "reactstrap";
import {Facebook, Instagram, Twitter} from "react-feather";
import {FaInstagram, FaTelegramPlane} from "react-icons/fa";
import {FiYoutube} from "react-icons/fi";
import {MdOutlineWhatsapp} from "react-icons/md";
import {SiAparat} from "react-icons/si";
import { Pinterest, Telegram, WhatsApp, YouTube } from '@material-ui/icons';
import data from "assets/data/configs/storeConfig.json"



const Ul = styled.ul`
  list-style: none;
  overflow-y: auto;
  font-size: 15px;
  display: flex;
  flex-flow: row nowrap;
  padding-right: 0;
  li {
    padding: 18px 10px;
  }
  flex-flow: column nowrap;
    background-color: #eaeaea;
    box-shadow: 0px 5px 2px 3px #d3d3d34f;
    position: fixed;
    transform: ${({open}) => open ? 'translateX(0)' : 'translateX(100%)'};
  top: 0;
  right: 0;
  height: 100vh;
  width: 336px;
  padding-top: 3.5rem;
  transition: transform 0.3s ease-in-out;
  z-index: 99998;

  li {
    color: #000;
  }
`;

const RightNav = ({logo, toggle, open}) => {
    const renderSocialIcons = (item) => {
        let n = item.link.lastIndexOf("/");
        let mediaId = item.link.substring(n + 1);
        switch (item.media) {
          case "instagram":
            return (
              <a href={item.link}>
                <span>
                  {" "}
                  <Instagram />
                </span>
              </a>
            );
          case "telegram":
            return (
              <a href={item.link}>
                <span>
                  {" "}
                  <Telegram/>
                </span>
              </a>
            );
          case "whatsApp":
            return (
              <a href={item.link}>
                <span>
                  {" "}
                  <WhatsApp />
                </span>
              </a>
            );
          case "pinterest":
            return (
              <a href={item.link}>
                <span>
                  {" "}
                  <Pinterest />
                </span>
              </a>
            );
          case "youtube":
            return (
              <a href={item.link}>
                <span>
                  {" "}
                  <YouTube />
                </span>
              </a>
            );
          case "twitter":
            return (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                title={mediaId}
              >
                <Twitter className="icon-twitter" />
              </a>
            );
          case "faceBook":
            return (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                title={mediaId}
              >
                <Facebook className="icon-facebook" />
              </a>
            );
          default:
            return null;
        }
      };
    return (
        <>
            <Ul open={open}>
                <div className="mb-2 text-center">
                    <img src={logoImg} alt="logo" title="logo" width={100}/>
                </div>
                {/*<div className="pr-1 pl-1 d-flex justify-content-between">
                    <NavLink to="/articles" className="menu-mobile-link" onClick={() => {
                        toggle()
                    }}>
                        مقاله ها
                        <img src={article} alt="article-logo" width="20px" className="mr-1 ml-1"/>
                    </NavLink>
                    <ContextLayout.Consumer>
                        {context => {
                            return (
                                <div className="menu-mobile-link" onClick={() => {
                                    toggle();
                                    context.showSupportModal(true);
                                }}>
                                    پشتیبانی
                                    <img src={support} alt="support-logo" width="20px" className="mr-1 ml-1"/>
                                </div>
                            )
                        }}
                    </ContextLayout.Consumer>
                </div>*/}
                <MobileMenu toggleMenu={toggle}/>
                <div className='nav-social'>
                {data.socialMedia.map((item) => renderSocialIcons(item))}
                </div>
            </Ul>

        </>
    )
}

export default RightNav
